import React from "react";

const OtherSkills = () => {
  return (
    <div className="otherSkills">
      <h3>Autres competences</h3>
      <div className="list">
        <ul>
          <li>
            <i className="fas fa-check-square"></i> NgXs
          </li>
          <li>
            <i className="fas fa-check-square"></i> Angular Matérial
          </li>
          <li>
            <i className="fas fa-check-square"></i> MySql / Oracle
          </li>
          <li>
            <i className="fas fa-check-square"></i> Méthode Agile (Scrum)
          </li>
        </ul>

        <ul>
          <li>
            <i className="fas fa-check-square"></i> AWS
          </li>
          <li>
            <i className="fas fa-check-square"></i> git
          </li>
          <li>
            <i className="fas fa-check-square"></i> Virtualbox / GNS3 / Tmate
          </li>
          <li>
            <i className="fas fa-check-square"></i> Anglais Intermediaires
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OtherSkills;
