import React from "react";

const Experience = () => {
  return (
    <div className="experience">
      <h3>Expérience</h3>
      <div className="exp-1">
        <h4>Stagiaire Développeur Font-End -- ÉQUIPE TECHNIQUE</h4>
        <h5>mai 2021 – août 2021</h5>
        <p>
          J'ai travailler dans un environnement Agile / Scrum avec des
          technologies récentes: Angular 12, NgXs, Jest (Tests Unitaires).
        </p>
      </div>
      <div className="exp-2">
        <h4>Équipier polyvalent de restaurant</h4>
        <h5>mars 2019 – nov. 2020</h5>
        <p>Au restaurant McDonald's</p>
      </div>

      <div className="exp-3">
        <h4>Réalisation d'une application client /serveur</h4>
        <h5>mai 2018 – juin 2018</h5>
        <p>
          Constitué d'une équipe de 6 personnes, on a été chargé de mettre en
          place une application d'architecture client / serveur Pour la gestion
          des services de la Direction Préfectoral de l'Education de Mamou.
        </p>
      </div>
    </div>
  );
};

export default Experience;
