import React, { Component } from "react";
import ProgressBar from "./ProgressBar";

class Languages extends Component {
  state = {
    languages: [
      { id: 1, value: "JavaScript / Ts", xp: 1.5 },
      { id: 2, value: "JAVA", xp: 1.8 },
      { id: 3, value: "C", xp: 1.8 },
      { id: 4, value: "Python", xp: 1 },
      { id: 5, value: "Php", xp: 1.2 },
      { id: 6, value: "HTML  / CSS / SCSS", xp: 1.6 },
    ],
    framworks: [
      { id: 1, value: "Angular", xp: 1.5 },
      { id: 2, value: "React", xp: 1.6 },
      { id: 3, value: "Symphony", xp: 1.6 },
      { id: 4, value: "Jest", xp: 1 },
      { id: 5, value: "SASS", xp: 1.5 },
      { id: 6, value: "Bootstrap", xp: 1 },
    ],
  };

  render() {
    let { languages, framworks } = this.state;

    return (
      <div className="languagesFrameworks">
        <ProgressBar
          languages={languages}
          className="languagesDisplay"
          title="languages"
        />

        <ProgressBar
          languages={framworks}
          className="frameworksDisplay"
          title="frameworks & bibliotèques"
        />
      </div>
    );
  }
}

export default Languages;
