import React, { Component } from "react";
import { portfolioData } from "../../data/portfolioData";
import Project from "./Project";

class ProjectList extends Component {
  state = {
    projects: portfolioData,
    radios: [
      /*{ id: 0, value: "Tous" },*/
      { id: 1, value: "javascript" },
      { id: 2, value: "react" },
      { id: 3, value: "php" },
      { id: 4, value: "css" },
      { id: 5, value: "c" },
      { id: 6, value: "xml" },
      { id: 7, value: "java" },
      { id: 8, value: "html" },
      { id: 9, value: "oracle" },
    ],
    selectedRadio: "c",
  };

  handelRadio = (e) => {
    let radio = e.target.value;
    this.setState({
      selectedRadio: radio,
    });
  };

  render() {
    let { projects, radios, selectedRadio } = this.state;

    return (
      <div className="portfolioContent">
        <ul className="radioDisplay">
          {radios?.map((radio) => {
            return (
              <li key={radio.id}>
                <input
                  type="radio"
                  name="radio"
                  checked={radio.value === selectedRadio}
                  value={radio.value}
                  id={radio.value}
                  onChange={this.handelRadio}
                />
                <label htmlFor={radio.value}> {radio.value} </label>
              </li>
            );
          })}
        </ul>

        <div className="projects">
          {projects
            .filter((item) => item.languages.includes(selectedRadio))
            .map((item) => {
              return <Project key={item.id} item={item} />;
            })}
        </div>
      </div>
    );
  }
}

export default ProjectList;
