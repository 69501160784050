import React from "react";
import Navigation from "../components/Navigation";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Contact = () => {
  return (
    <div className="contact">
      <Navigation />

      <div className="contactContent">
        <div className="header"></div>

        <div className="contactBox">
          <h1>Contactez-moi</h1>
          <ul>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              <span>Grenoble</span>
            </li>

            <li>
              <i className="fas fa-mobile-alt"></i>
              <CopyToClipboard text="0755834542">
                <span
                  className="clickInput"
                  onClick={() => {
                    alert("Numéro de téléphone copié !");
                  }}
                >
                  07 55 83 45 42
                </span>
              </CopyToClipboard>
            </li>

            <li>
              <i className="far fa-envelope"></i>
              <CopyToClipboard text="bahalioufgn@gmail.com">
                <span
                  className="clickInput"
                  onClick={() => {
                    alert("Adresse e-mail copié !");
                  }}
                >
                  bahalioufgn@gmail.com
                </span>
              </CopyToClipboard>
            </li>
          </ul>
        </div>

        <div className="socialNetwork">
          <ul>
            <a
              href="https://www.linkedin.com/in/bah-thierno/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>linkedin</h4>
              <i className="fab fa-linkedin"></i>
            </a>

            <a href="#" target="_blank" rel="noopener noreferrer">
              <h4>codePen</h4>
              <i className="fab fa-codepen"></i>
            </a>

            <a
              href="https://github.com/l3miage-baht"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>github</h4>
              <i className="fab fa-github"></i>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
