export const portfolioData = [
  {
    id: 1,
    name: "Jeu des petits chevaux",
    languages: ["c"],
    languagesIcons: ["fab fa-cuttlefish", "fab fa-js", "fab fa-css3-alt"],
    source: "http://www.github.com",
    info: "Réalisé en C, Le but de ce projet était de programmer un jeu de petits chevaux dans lequel chaque joueur est un processus distinct et les joueurs communiquent via des tubes. Le soin a été mis sur la gestion des processus, des communications, des synchronisations. http://www.petits-chevaux.com/tout-sur-les-petits-chevaux.htm",
    picture: "./media/Jeudespetits chevaux.jpg",
  },
  {
    id: 2,
    name: "Jeu vidéo et une application de gestion de cabinet infirmier",
    languages: ["xml", "java", "xpath", "xslt", "xmlshema"],
    languagesIcons: ["fab fa-java"],
    source: "http://www.github.com",
    info: "Back-end : XML (XPATH, schéma XML, XSLT) - Front-End : Angular, Ajax, JQuery, Java (Env3D)",
    picture: "./media/project2.JPG",
  },
  {
    id: 3,
    name: "App web Gestion Théâtre",
    languages: ["html", "css", "oracle", "php"],
    languagesIcons: ["fab fa-html5", "fab fa-css3-alt", "fab fa-php", ""],
    source: "http://www.github.com",
    info: "Front-end : HTML5, CSS3  -  Back-end : Oracle, PHP",
    picture: "./media/project3.JPG",
  },
  {
    id: 4,
    name: "Mon CV perso",
    languages: ["react"],
    languagesIcons: ["fab fa-react", "fab fa-js", "fab fa-css3-alt"],
    source: "http://www.github.com",
    info: "Projet personnel sur la réalisation mon CV qui sera hébergé en ligne.",
    picture: "./media/project4.JPG",
  },
];
